.ternyx-page {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
  color: #333;
}

.ternyx-page section {
  margin-bottom: 3rem;
}

/* Hero Section */
.hero-section {
  text-align: center;
  padding: 3rem 0;
}

.hero-section h1 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  color: #1a73e8;
}

.hero-section h3 {
  font-size: 1.5rem;
  font-weight: 400;
  color: #5f6368;
}

/* Overview Section */
.overview-section p {
  font-size: 1.1rem;
  line-height: 1.6;
  max-width: 800px;
  margin: 0 auto;
}

/* Features Section */
.features-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1.5rem;
  margin-top: 2rem;
}

.feature-card {
  background-color: #f8f9fa;
  border-radius: 8px;
  padding: 1.5rem;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.feature-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

.feature-card h3 {
  color: #1a73e8;
  margin-bottom: 0.5rem;
}

/* Components Section */
.components-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1.5rem;
  margin-top: 2rem;
}

.component {
  padding: 1rem;
  border-left: 3px solid #1a73e8;
  background-color: #f8f9fa;
}

.component h3 {
  margin-bottom: 0.5rem;
  color: #1a73e8;
}

/* Business Model Section */
.licensing-options {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
  margin-top: 2rem;
}

.option {
  text-align: center;
  padding: 2rem;
  border-radius: 8px;
  background-color: #f8f9fa;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
}

.option h3 {
  color: #1a73e8;
  margin-bottom: 1rem;
}

/* Performance Metrics */
.metrics-table {
  overflow-x: auto;
  margin-top: 2rem;
}

.metrics-table table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
}

.metrics-table th,
.metrics-table td {
  padding: 0.75rem 1rem;
  text-align: left;
  border-bottom: 1px solid #e0e0e0;
}

.metrics-table th {
  background-color: #f1f3f4;
  font-weight: 600;
  color: #5f6368;
}

.metrics-table tr:hover {
  background-color: #f8f9fa;
}

/* Integration Assets */
.integration-assets ul {
  margin-top: 1.5rem;
  list-style-type: none;
  padding-left: 0;
}

.integration-assets li {
  padding-left: 1.5rem;
  position: relative;
  margin-bottom: 0.75rem;
}

.integration-assets li::before {
  content: "✓";
  position: absolute;
  left: 0;
  color: #1a73e8;
}

/* CTA Section */
.cta-section {
  text-align: center;
  padding: 3rem;
  background-color: #f8f9fa;
  border-radius: 8px;
  margin-top: 3rem;
}

.cta-buttons {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-top: 1.5rem;
}

.cta-button {
  display: inline-block;
  padding: 0.75rem 1.5rem;
  border-radius: 4px;
  text-decoration: none;
  font-weight: 600;
  transition: background-color 0.3s ease;
}

.cta-button.primary {
  background-color: #1a73e8;
  color: white;
}

.cta-button.primary:hover {
  background-color: #0d62d1;
}

.cta-button.secondary {
  background-color: white;
  color: #1a73e8;
  border: 1px solid #1a73e8;
}

.cta-button.secondary:hover {
  background-color: #f0f7ff;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .hero-section h1 {
    font-size: 2rem;
  }
  
  .hero-section h3 {
    font-size: 1.2rem;
  }
  
  .features-grid,
  .components-list,
  .licensing-options {
    grid-template-columns: 1fr;
  }
  
  .cta-buttons {
    flex-direction: column;
  }
} 